











import { Component, Vue } from "vue-property-decorator";
import { NewsPostIlustration, NewsReadingIlustration } from "./ilustrations";
@Component({
  components: {
    NewsPostIlustration,
    NewsReadingIlustration
  }
})
export default class NewsComponent extends Vue {}
