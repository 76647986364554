<template>
  <div class="news-page">
    <NewsComponent/>
  </div>
</template>


<script>
import NewsComponent from "@/components/NewsComponent.vue";

export default {
  name: "NewsPage",
  components: {
    NewsComponent
  }
};
</script>

<style>
  .news-page {
  background: #E1F2F9;
  min-height: calc(100vh - 100px);
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
}

</style>